import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = {
  key: 0,
  class: "p-field p-col-12 p-fluid"
}
const _hoisted_3 = {
  key: 0,
  class: "internal-name-text"
}
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = {
  key: 0,
  class: "p-grid"
}
const _hoisted_6 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_7 = { class: "card-selected-supplement" }
const _hoisted_8 = { class: "card-content" }
const _hoisted_9 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_10 = { for: "type" }
const _hoisted_11 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_12 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_13 = { for: "amount" }
const _hoisted_14 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_15 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_16 = { for: "numberOfTakes" }
const _hoisted_17 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_18 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_19 = { for: "frequencyOfTakes" }
const _hoisted_20 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_21 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_22 = { for: "startDate" }
const _hoisted_23 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_24 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_25 = { for: "endDate" }
const _hoisted_26 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_27 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_28 = { for: "observations" }
const _hoisted_29 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.$t('supplementation.patient.create-modal.title'),
    modal: true
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.save'),
        class: "p-button p-component p-button-info p-button-medium",
        loading: _ctx.waitSubmit,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.subtitle')), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.supplementPatientFormDto.supplementId == '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_Dropdown, {
                  id: "supplementId",
                  modelValue: _ctx.supplementPatientFormDto.supplementId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.supplementPatientFormDto.supplementId) = $event)),
                  options: _ctx.supplementsOptions,
                  "option-label": "label",
                  "option-value": "value",
                  class: _normalizeClass({
              'p-invalid': _ctx.showValidation && !_ctx.validateForm.supplementId.valid,
            })
                }, {
                  option: _withCtx((slotProps) => [
                    _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1),
                    (slotProps.option.internalName)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(`[${slotProps.option.internalName}]`), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.supplementId.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.validateForm.supplementId.message}`)), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.supplementPatientFormDto.supplementId != '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "icon__container p-mr-2" }, [
                    _createElementVNode("i", {
                      class: "icon-capsules",
                      "aria-hidden": "true"
                    })
                  ], -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.selectedSupplement?.name), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.renderTypes(_ctx.selectedSupplement?.types)), 1)
                  ]),
                  _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    class: "custom-close-button p-button-rounded p-button-only-icon",
                    onClick: _ctx.deleteSelectedSupplement
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createElementVNode("i", {
                        class: "icon-close_big",
                        "aria-hidden": "true"
                      }, null, -1)
                    ])),
                    _: 1
                  }, 8, ["onClick"])), [
                    [
                      _directive_tooltip,
                      _ctx.$t('common.delete'),
                      void 0,
                      { bottom: true }
                    ]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.unit')) + "*", 1),
                _createVNode(_component_Dropdown, {
                  id: "type",
                  modelValue: _ctx.supplementPatientFormDto.unit,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.supplementPatientFormDto.unit) = $event)),
                  options: _ctx.unitsOptions,
                  "option-label": "label",
                  "option-value": "value",
                  class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.unit.valid })
                }, null, 8, ["modelValue", "options", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.unit.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.$t(`${_ctx.validateForm.unit.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.labelAmount) + "*", 1),
                _createVNode(_component_InputNumber, {
                  id: "amount",
                  modelValue: _ctx.supplementPatientFormDto.amount,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.supplementPatientFormDto.amount) = $event)),
                  modelModifiers: { trim: true },
                  required: "true",
                  "max-fraction-digits": 2,
                  class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.amount.valid })
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.amount.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.$t(`${_ctx.validateForm.amount.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.numberOfTakes')) + "*", 1),
                _createVNode(_component_Dropdown, {
                  id: "numberOfTakes",
                  modelValue: _ctx.supplementPatientFormDto.numberOfTakes,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.supplementPatientFormDto.numberOfTakes) = $event)),
                  options: _ctx.numberOfTakesOptions,
                  "option-label": "label",
                  "option-value": "value",
                  class: _normalizeClass({
              'p-invalid': _ctx.showValidation && !_ctx.validateForm.numberOfTakes.valid,
            })
                }, null, 8, ["modelValue", "options", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.numberOfTakes.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.$t(`${_ctx.validateForm.numberOfTakes.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.frequencyOfTakes')) + "*", 1),
                _createVNode(_component_InputText, {
                  id: "frequencyOfTakes",
                  modelValue: _ctx.supplementPatientFormDto.frequencyOfTakes,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.supplementPatientFormDto.frequencyOfTakes) = $event)),
                  modelModifiers: { trim: true },
                  required: "true",
                  class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.frequencyOfTakes.valid })
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.frequencyOfTakes.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_20, _toDisplayString(_ctx.$t(`${_ctx.validateForm.frequencyOfTakes.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.startDate')) + "*", 1),
                _createVNode(_component_Calendar, {
                  id: "startDate",
                  modelValue: _ctx.supplementPatientFormDto.startDate,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.supplementPatientFormDto.startDate) = $event)),
                  class: _normalizeClass({
              'p-invalid': _ctx.showValidation && !_ctx.validateForm.startDate.valid,
            })
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.startDate.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_23, _toDisplayString(_ctx.$t(`${_ctx.validateForm.startDate.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.endDate')) + "*", 1),
                _createVNode(_component_Calendar, {
                  id: "endDate",
                  modelValue: _ctx.supplementPatientFormDto.endDate,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.supplementPatientFormDto.endDate) = $event)),
                  minDate: _ctx.minEndDate,
                  class: _normalizeClass({
              'p-invalid': _ctx.showValidation && !_ctx.validateForm.endDate.valid,
            })
                }, null, 8, ["modelValue", "minDate", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.endDate.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_26, _toDisplayString(_ctx.$t(`${_ctx.validateForm.endDate.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('supplementation.patient.create-modal.observations')), 1),
                _createVNode(_component_Textarea, {
                  id: "observations",
                  modelValue: _ctx.supplementPatientFormDto.observations,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.supplementPatientFormDto.observations) = $event)),
                  class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.observations.valid }),
                  rows: "5",
                  "auto-resize": true,
                  type: "text"
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.observations.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_29, _toDisplayString(_ctx.$t(`${_ctx.validateForm.observations.message}`)), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}