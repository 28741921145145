/**
 * Interface for validation with value valid true if correct validation and valid false with an error message if not
 */
export interface ValidationResult {
  valid: boolean;
  message?: string;
}

/**
 * Function to validate if required field has value
 * @param value value of a field
 * @returns  returns a ValidationResult
 */
export function required(value: any) {
  if (!value) {
    return {
      valid: false,
      message: 'common.requiredField',
    };
  }
  if (Array.isArray(value) && value.length == 0) {
    return {
      valid: false,
      message: 'common.requiredField',
    };
  }
  return { valid: true };
}

/**
 * Function to validate if required field has value
 * @param value value of a field
 * @param condition if condition check required field
 * @returns  returns a ValidationResult
 */
export function requiredIfCondition(value: any, condition: boolean) {
  if (condition) {
    return required(value);
  }
  return { valid: true };
}

/**
 * Function to validate if a string has a valid email pattern
 * @param value any string
 * @returns  returns a ValidationResult
 */
export function hasEmailPattern(value: string) {
  const validPattern = /^[^\s@]+@[^\s@]+\.\S+$/.test(value);
  if (validPattern) {
    return { valid: true };
  }

  return {
    valid: false,
    message: 'messages.invalidEmail',
  };
}

/**
 * Function to validate if a value exist in a given list
 * @param value any
 * @returns  returns a ValidationResult
 */
export function notExistInList(value: any, message: string | undefined, list: any[]) {
  if (list.find((item) => item === value) != undefined) {
    return {
      valid: false,
      message: message || 'invalid',
    };
  }
  return { valid: true };
}

/**
 * Function to validate if the email field is correct
 * @param value value of an email field
 * @returns  returns a ValidationResult
 */
export function validateEmail(
  value: any,
  messageIfExist: string | undefined = undefined,
  emailList: string[] | undefined = undefined,
) {
  const result = required(value);
  if (!result.valid) {
    return result;
  }
  if (emailList) {
    const resultExist = notExistInList(value, messageIfExist, emailList);
    if (!resultExist.valid) {
      return resultExist;
    }
  }
  return hasEmailPattern(value);
}

export function isObjectValid<Type>(form: Type) {
  let fieldKey: keyof typeof form;
  let result = true;

  for (fieldKey in form) {
    const field = form[fieldKey] as unknown as ValidationResult;
    if (!field || !field.valid) {
      result = false;
    }
  }
  return result;
}
